:root {
    --rmdp-primary: #800080; /* رنگ اصلی */
    --rmdp-background: lavender; /* رنگ پس زمینه */
    --rmdp-selected: indigo; /* رنگ تاریخ انتخاب شده */
}

.rmdp-day.rmdp-today span{
    background-color: #20bbcc;
}

.rmdp-day.rmdp-selected span:not(.highlight){
    background-color: #6b21f3 !important;
}

.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover{
    background-color: #6b21f397;
}

.rmdp-week-day{
    color: #6b21f3;
}

.rmdp-arrow{
    border-color: #6b21f3;
}

.rmdp-arrow-container:hover{
    background-color: #6b21f3 !important;
}

.rmdp-container {
    width: 100%;
}

.rmdp-input{
    padding: 12px 40px 12px 40px !important;
    height: 50px !important;
    width: 100% !important;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.06) !important;
    border: solid rgb(229 231 235 / 0.7) !important;
    border-radius: 0.75rem !important;
    display: block !important;
    user-select: none !important;
}   

.rmdp-input:focus{
    border-color: #6b21f3 !important;
}